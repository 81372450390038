import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import CookieConsent from "react-cookie-consent";
import './layout.scss'
import { GlobalDebug } from "../utils/remove-console";



const Layout = ({ children }) => {
  useEffect(() => {
    (process.env.GATSBY_NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false, true);
  }, []);



  return (
    <div id="layoutcontainer">




      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        enableDeclineButton
        declineButtonText="Ablehnen"
        cookieName="_ga"
        flipButtons
        buttonClasses="btn btn-landing"
        declineButtonClasses="btn-2 btn-black"
        style={{ background: "#101820", fontSize: "15px", flex: "auto", textAlign: "left", fontFamily: "Futura" }}
        expires={150}

      >
        Wir verwenden Cookies, um Inhalte anzuzeigen, zu personalisieren und die Zugriffe auf unsere Website zu analysieren.
      </CookieConsent>

      <main>{children}</main>




    </div>
  )

}


export default Layout

import React from 'react'
import Layout from './src/layouts/index'
import Cursor from './src/components/cursor/cursor'


export const wrapPageElement = ({ element, props }) => {



    return (
        <div className={`${props?.pageContext?.edge?.slug === "landing" ? "landing" : ""}`}>

            <Cursor></Cursor>
            <Layout className="landing" {...props}>{element}</Layout>
        </div>
    )
}

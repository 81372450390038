import React from "react"

import Cur from "react-cursor-follow"

import "./cursor.scss"



export default function Cursor() {
    // const { cursorType } = useContext(MouseContext); used from step6


    return (
        <Cur duration={0} size={20} />
    )
}
